<template>
  <section class="products">
    <v-img
      v-if="banner"
      :src="banner"
      max-height="420"
    >
      <v-layout
        align-center
        fill-height
        justify-center
        style="position: relative; text-align: center;"
      >
      </v-layout>
    </v-img>
    <v-container  grid-list-md text-center class="ctk-div"  id="ctk_top" style="padding-top:10px !important;">
      <div>
        <v-breadcrumbs :items="breadcrumbs" divider=" / "></v-breadcrumbs>
      </div>
      <h3 style="text-align: left;margin-bottom: 30px;">{{category.name}}</h3>
      <v-flex v-if="category" class="text-left" style="margin-bottom: 30px;">
        <span v-html="category.desc">{{category.desc}}</span>
      </v-flex>
      <v-layout wrap>
        <v-flex
          v-for="(product, i) in products"
          :key="i"
          xs12
          pa-2
          class="md4 ctk-jx-category"
        >
          <v-card
            class="mx-auto ctk-product-card"
          >
            <a :href="'/field/' + product.id">
              <v-img
                :src="product.img"
                aspect-ratio="1"
                class="ctk-product-img"
              ></v-img>
              <div class="ctk-product-text">
                <h1>{{product.title}}</h1>
                <p>{{product.desc}}</p>
              </div>
            </a>
          </v-card>
        </v-flex>
      </v-layout>
      <template>
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="totalPage"
            circle
          ></v-pagination>
        </div>
      </template>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    breadcrumbs: [],
    category: { name: '', desc: '' },
    products: null,
    techs: null,
    catId: null,
    banner: null,
    pageId: 8,
    totalPage: 1,
    page: 1
  }),
  created () {
    document.querySelector('#menu_field').classList.add('v-btn--active')
    this.catId = this.$route.params.pid
    this.getCategory(this.catId)
    this.getProducts(this.page, this.catId)
  },
  mounted () {
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.banner = val.banner[this.pageId][0].img
      },
      deep: true
    },
    page: {
      handler: function (val, oldVal) {
        document.querySelector('#ctk_top').scrollIntoView(true)
        this.getProducts(val, this.catId)
      },
      deep: true
    }
  },
  computed: {
  },
  methods: {
    getCategory (id) {
      this.https.get('field_category', { id: id }).then(response => {
        if (response.code === 0) {
          this.category = response.data.cat
          this.techs = response.data.tech
          this.breadcrumbs = [
            { text: '首页', disabled: false, href: '/' },
            { text: '修复运用', disabled: true },
            { text: this.category.name, disabled: true }
          ]
          if (this.category.banner) {
            this.banner = this.category.banner
          }
        }
      })
    },
    getProducts (page, cid) {
      this.https.get('fields', { page: page, cid: cid, pageSize: 12 }).then(response => {
        if (response.code === 0) {
          this.products = response.data.data
          this.totalPage = response.data.last_page
          this.page = page
        }
      })
    }
  }
}
</script>
<style>
 .ctk-jx-category .ctk-product-card {
   background: #fff !important;
   padding: 20px 20px 0 20px !important;
   box-shadow:0px 4px 10px 0px rgba(0,0,0,0.08) !important;
 }
 .ctk-jx-category .ctk-product-card:hover {
   background: #C70805 !important;
 }
 .ctk-product-img {
   height: 240px;
 }
</style>
